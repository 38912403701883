import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";

import {  Title, Section, Box, Text, Span } from "../../components/Core";

import { Parallax, Background } from 'react-parallax';
import Rarrow from "../../assets/image/svg/rarrow.svg";
import { breakpoints } from "../../utils";

import imgC1 from "../../assets/image/jpeg/ultimate-mesotherapy.jpg";


import { device } from "../../utils";
import Newarrow from '../../assets/image/jpeg/enter.svg';
import iconLayout from "../../assets/image/svg/time.svg";
import iconLayers from "../../assets/image/svg/cost.svg";
import iconResponsive from "../../assets/image/svg/recovery.svg";
import results from "../../assets/image/svg/results.svg";
import pain from "../../assets/image/svg/pain.svg";
import last from "../../assets/image/svg/last.svg";

const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;






const Arrow = styled.div`


width: 1.8em;
margin-left: 0.8em;
opacity: 0.6;

`;



const Iwrap = styled.div`

display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-bottom: 1em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;


@media screen and (max-width: 767px)
{
    margin-bottom: 8px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@media screen and (max-width: 991px)
 {
    margin-bottom: 8px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
}


`;



const Iline = styled.div`

width: 6em;
height: 1px;
margin-right: 1em;
background-color: rgb(171, 142, 102);

@media screen and (max-width: 991px)
 {
  width: 3em;
}

`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #ffffff;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;








const Secondtext = styled.h2`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #ffffff;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;



const BookbuttonNew = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #1a1a1a;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #1a1a1a;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;

const HeroImage = styled.div`

background:url(${imgC1}) no-repeat;
height: 100%;
background-position: center;
background-repeat: no-repeat;
background-size: cover;
position: relative;
margin:0;
padding-top:100px ;
padding-bottom:100px;
border-top:10px solid #d4afb9;
`;



const HeroT = styled.h2`
  letter-spacing: 0px;
  font-size: 18px;
  font-weight: 800;
  line-height: 26px;
  color: white;
  margin-bottom: 10px;

  @media ${device.sm} {
    font-size: 66px;
    line-height: 70px;
  }

  @media ${device.lg} {
    font-size: 30px;
    line-height: 40px;
  }

  @media ${device.xl} {
    font-size: 30px;
    line-height: 40px;
  }
`;

const SliderStyled = styled(Slider)`
  .slick-dots {
    position: relative;
    margin-top: 10px;
    li {
      font-size: 0;
      width: 17px;
      height: 8px;
      border-radius: 4px;
      background-color: ${({ theme }) => theme.colors.shadow};
      margin-left: 5px;
      margin-right: 5px;
      transition: 0.5s;
      &.slick-active {
        width: 45px;
        height: 8px;
        border-radius: 4px;
        background-color: ${({ theme }) => theme.colors.secondary};
      }
      button {
        width: 100%;
        height: 100%;
        &:before {
          content: none;
        }
      }
    }
  }
`;

const FeatureCard = ({ iconImage, title, children, ...rest }) => (
  <Box {...rest}>
    <Box className="d-flex align-items-center " mb={[0, 0, 0, 0]} pb={0}>
      <img src={iconImage} alt="" width="30px" className="mr-3" />
      <Text color="#000 " fontSize="14px" variant="medium">{children}</Text>
    </Box>
    <Box>
     
     
    </Box>
  </Box>
);


const ContentCard = ({
  className,
  image,
  name,
  company,
  children,
  ...rest
}) => (
  <Box
  
    
    mx="3px"
   
    className={`${className}`}
    {...rest}
    css={`
      &:focus {
        outline: none;
      }
    `}
  >
    <Text color="dark" mb={4}>
      {children}
    </Text>
    <Box className={`d-flex justify-content-between`}>
      <Box className="d-flex justify-content-center align-items-start" mr={3}>
        <img src={image} alt="Non Surgical Nose Job London" className="img-fluid" />
      </Box>
      <div className="flex-grow-1">
        <Title variant="card" mb={0}>
          {name}
        </Title>
        <Text fontSize={2}>{company}</Text>
      </div>
    </Box>
  </Box>
);

const UltimatePage = () => (
    <>
      <Section id="ultimate" py="0"  bg="#f7f7fb" className="pb-md-5 mb pt-md-0">

     <Parallax>
     <HeroImage>
        <Container >

      
        <Row className="align-items-center">
            <Col md="9" lg="12" className="">
      
                   <Iwrap>


<Itext as="h2">Ultimate Mesotherapy Facial Treatment</Itext>
</Iwrap>
      <Secondtext>
     Feed your skin
      </Secondtext>


            
             
            </Col>
            </ Row>
  



        </Container> 
        </HeroImage></Parallax>
        <Container className="pb-3 mb pt-5">

   


            <Row>
         
            <Col lg="12"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-once="false"
            data-aos-delay="500"
            >

                <Text color="dark">


                Give your skin a boost of energy, radiance and ‘lit-from-within’ skin glow, with the ultimate Mesotherapy facial treatment. Mesotherapy is a non-surgical technique of rejuvenation that involves microinjections of vitamins, anti-oxidants, enzymes and plant extract cocktails that get delivered into the mesodermal layer of the skin.   <br /> <br /> 

Following a deep cleansing and purifying of the face, chemical peel is applied, which improves skin texture and firmness, and decreases fine lines and wrinkles. Then, Mesotherapy injections are delivered to hydrate, rejuvenate and tighten the skin. Together, skin is regenerated and restructured, improving radiance and hydration of the skin and improving hyperpigmentation and acne.  <br /> <br /> 

A single chemical peel can provide the immediate effect of bright and radiant skin, as the superficial layer of the skin is cleared, helping the mesotherapy cocktail to penetrate deeper into the skin. Mesotherapy gives you the comfort and knowledge that only potent nutrients are infiltrating your skin to renew, stimulate and nourish it. Applying mesotherapy after chemical peel will boost skin radiance and ensure optimal hydration from within – a powerful combination that can deliver optimum results. <br /> <br /> 
 

 
<Text color="black">Ultimate Mesotherapy is suitable and ideal for:<br /> </Text>


<FeatureCard title="Treatment Time" iconImage={Rarrow}>
Tired, dull, dehydrated skin.
         </FeatureCard>

<FeatureCard title="Treatment Time" iconImage={Rarrow}>
Anti-ageing: to improve fine lines, skin laxness and texture 
         </FeatureCard>

         <FeatureCard title="Treatment Time" iconImage={Rarrow}>
         Sun damage 
         </FeatureCard> 
         <FeatureCard title="Treatment Time" iconImage={Rarrow}>
         Improve skin tone
         </FeatureCard>
         <FeatureCard title="Treatment Time" iconImage={Rarrow}>
         Improve Acne
         </FeatureCard>
         <FeatureCard title="Treatment Time" iconImage={Rarrow}>
         Improve Pigmentation 
         </FeatureCard>
         
         <br />

         <Text color="black">Key benefits:<br /> </Text>


<FeatureCard title="Treatment Time" iconImage={Rarrow}>
Suits and improves all skin types
         </FeatureCard>

<FeatureCard title="Treatment Time" iconImage={Rarrow}>
No downtime or recovery needed
         </FeatureCard>

         <FeatureCard title="Treatment Time" iconImage={Rarrow}>
         Instant results that will continue to improve with subsequent treatments
         </FeatureCard> <br />

        

        

                </Text>
         
            </Col>
          </Row>

          
          <Row className="justify-content-center">
       
       <Col lg="12" xs="12" className="mb-1 mb-lg-2 "
         data-aos="fade-up"
         data-aos-duration="1000"
         data-aos-once="false"
         data-aos-delay="100" >
           <Text color="black">
<b>Treatment Summary</b>  <br /></Text>
         <FeatureCard title="Treatment Time" iconImage={iconLayout}>
         45 Minutes Appointment Time (Including Consultation)
         </FeatureCard>
       </Col>
       
       <Col lg="12" xs="12" className="mb-1 mb-lg-2"   data-aos="fade-up"
           data-aos-duration="1000"
           data-aos-once="false"
           data-aos-delay="200">
         <FeatureCard title="Instant Results" iconImage={results}>
        Results are Instant

         </FeatureCard>
       </Col>
       
       <Col lg="12" xs="12" className="mb-1 mb-lg-2"   data-aos="fade-up"
           data-aos-duration="1000"
           data-aos-once="false"
           data-aos-delay="300">
         <FeatureCard title="Recovery" iconImage={iconResponsive}>
        No recovery time needed

         </FeatureCard>
       </Col>
       <Col lg="12" xs="12" className="mb-1 mb-lg-2" data-aos="fade-up"
           data-aos-duration="1000"
           data-aos-once="false"
           data-aos-delay="400">
         <FeatureCard title="Pain" iconImage={pain}>
None / Mild
         </FeatureCard>
       </Col>
       <Col lg="12" xs="12" className="mb-1 mb-lg-2" data-aos="fade-up"
           data-aos-duration="1000"
           data-aos-once="false"
           data-aos-delay="500">
         <FeatureCard title="Cost" iconImage={iconLayers}>
           Treatment cost £250
         </FeatureCard>
       </Col>
       
    
     </Row>

          <Row>
            <Col className="pt-4 pb-5">
            <Box>
          
            

                <br /> 
              <a href 
                                            ="https://connect.pabau.com/bookings.php?compid=8607" 
                                            activeClassName="active"
                                           
                                          
                                        >      <Bookbutton>
                
                
                Book Online
                
                
                </Bookbutton> </a>
                </Box>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );


export default UltimatePage;
